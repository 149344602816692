
$primary: #008c95;
$light: #757575;
$dark: #333333;
$secondary:  #009a52;
$white: #f8f8f8;
$light_alpha: #aaaaaa77;
$black: black;


$images_top: #fbfbfbcf;
$images_bottom: $white;