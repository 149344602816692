@import "../_colors.scss";
@import "../_dimensions.scss";


.section-title{
    height: $section_title_height;
    background-color: $primary+"aa";
    color: $white;
    box-shadow: 3px 3px 3px #88888888;
}

.section-title h1{
    color: $white !important;
    margin-top: -5px;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .section-title {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.section-icon{
    margin-top: -16px;
    margin-right: 10px;
    width:80px;
    height:80px;
    animation:spin 30s linear infinite;
    stroke: $white;
    stroke-width: 20;
}
@keyframes spin { 
    100% { 
        transform:rotate(360deg); 
    } 
}

.section-id{
    position: relative;
    top: -$navbar_height;
}

.min-height{
    min-height: 30vw;
}

.section{
    background-size: cover;
    background-repeat: no-repeat;
}

@function custom_image_gradient(){
    @return linear-gradient(180deg, rgba($primary, 0.8) $section_title_height, $images_top $section_title_height, $images_bottom 90%);
}

.section-background-1{
    background-image: 
        custom_image_gradient(), 
        url("../img/im1.jpg");
}

.section-background-2{
    background-image: 
        custom_image_gradient(), 
        url("../img/im2.jpg");
}

.section-background-3{
    background-image: 
        custom_image_gradient(), 
        url("../img/im3.jpg");
}

.section-background-4{
    background-image: 
        custom_image_gradient(), 
        url("../img/im4.jpg");
}

.section-background-5{
    background-image: 
        custom_image_gradient(), 
        url("../img/im5.jpg");
}

.section-background-6{
    background-image: 
        custom_image_gradient(), 
        url("../img/im6.jpg");
}