@import "../../../../colors";

th, td{
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: large;
}

th{
    color: $primary;
}